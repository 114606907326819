<template>
  <el-card class="box-card full-screen cash-record-statistics">
    <div ref="pageHead" class="table-page-head">
      <el-form :inline="true" :model="queryForm">
        <el-form-item :label="$t('tradeAssistant')" class="el-form-item-long-frame">
          <el-select v-model="queryForm.orgUserId" filterable size="small" :placeholder="$t('all')">
            <el-option value="" :label="$t('all')"></el-option>
            <el-option v-for="(t,i) in orgUser" :key="i" :label="t.name+' '+t.surname+' -- '+t.loginName " :value="t.loginName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('amount')">
          <el-input v-model="queryForm.amount" size="small" />
        </el-form-item>
        <el-form-item :label="$t('status')">
          <el-select v-model="queryForm.status" size="small">
            <el-option value="" :label="$t('all')"></el-option>
            <el-option value="0" :label="$t('unreceived')"></el-option>
            <el-option value="1" :label="$t('received')"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getList" :loading="isLoading"><i class="el-icon-search"></i> {{$t('query')}}</el-button>
        </el-form-item>
      </el-form>
      <el-row class="table-btns" v-if="loginInfo.orgType!=1">
        <el-button type="text" size="mini" icon="el-icon-edit" @click="cashGrant()">{{$t('issuedCash')}}</el-button>
      </el-row>
    </div>

    <div class="data-table" :style="{top:queryTableHeight,height:`calc(100% - ${queryTableHeight} - 70px)`}">
      <el-table :data="tableData.records" v-loading="isLoading" element-loading-background="rgba(255,255,255,0.4)" size="mini" height="100%">
        <el-table-column type="index" align="center" fixed="left" width="60"></el-table-column>
        <el-table-column prop="name" :label="$t('tradeAssistant')" min-width="160">
          <template slot-scope="scope">
            {{scope.row.name}} {{scope.row.surname}}({{scope.row.loginName}})
          </template>
        </el-table-column>
        <el-table-column prop="amount" :label="$t('amount')" min-width="160">
          <template slot-scope="scope">
            <span class="franc">₣</span> {{scope.row.amount.formatMoney(0)}}
          </template>
        </el-table-column>
        <el-table-column prop="status" :label="$t('status')" min-width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.status==0" class="text-danger">{{$t('unreceived')}}</span>
            <span v-if="scope.row.status==1" class="text-success">{{$t('received')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" :label="$t('time')" min-width="160">
          <template slot-scope="scope">
            {{$manba(scope.row.createTime).format('ymdhms')}}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination background layout="total,sizes,prev,pager,next,jumper" :total="tableData.total||0" :page-sizes="[20, 30, 50, 100, 200]" :page-size="pageSize" :current-page="currentPage" @size-change="setPageSzie" @current-change="setCurrentChange" @prev-click="setCurrentChange" @next-click="setCurrentChange" />

    <el-drawer :title="$t('cashIssued')" :visible.sync="drawer" direction="rtl" :modal="false" :destroy-on-close="true" :wrapperClosable="false">
      <el-form :model="addForm" :rules="rules" ref="addForm">
        <el-form-item :label="$t('tradeAssistant')" prop="tradeAssistant">
          <el-select v-model="addForm.tradeAssistant" multiple filterable>
            <el-option v-for="(t,i) in orgUser" :label="t.name+' '+t.surname+' -- '+t.job" :value="t.id" :key="i"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('amount')" prop="amount">
          <el-input type="number" size="medium" v-model="addForm.amount" maxlength="7" />
        </el-form-item>
        <el-form-item :label="$t('remark')">
          <el-input type="textarea" size="medium" v-model="addForm.remark" />
        </el-form-item>
        <div class="bottom-button">
          <el-button size="medium" type="primary" :loading="isLoading" @click="onSubmit('addForm')">{{$t('submit')}}</el-button>
        </div>
      </el-form>
    </el-drawer>
  </el-card>
</template>

<script>
import org from '@/api/org'
import card from '@/api/card'

export default {
  name: "cashIssued",
  data () {
    return {
      queryForm: {},
      //table高度，动态设置
      queryTableHeight: String,
      //分页器默认参数
      pageSize: 20,
      currentPage: 1,
      tableData: {},
      //是否加载中
      isLoading: false,
      //列表数据
      table: new Array(),
      drawer: false,
      addForm: {},

      orgUser: [],
    }
  },
  computed: {
    loginInfo () { return this.$store.state.globalParameter.loginInfo; },
    rules () {
      return {
        tradeAssistant: [{ type: "array", required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        amount: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
      }
    }
  },
  methods: {
    getList () {
      let _this = this;
      card.pageCashReceive({
        param: { current: _this.currentPage, size: _this.pageSize, type: 0, ..._this.queryForm },
        success: res => {
          _this.tableData = res.data || {};
        }
      })
    },
    setPageSzie (val) {
      this.pageSize = val;
      this.getList();
    },
    setCurrentChange (val) {
      this.currentPage = val;
      this.getList();
    },
    cashGrant () {
      this.drawer = true;
    },
    onSubmit (refName) {
      let _this = this;
      _this.$refs[refName].validate(valid => {
        if (!valid) return false;
        card.grantCash({
          param: {
            orgUserIds: _this.addForm.tradeAssistant.toString(),
            amount: _this.addForm.amount,
            remark: _this.addForm.remark
          },
          success: res => {
            if (res.code == 200) {
              _this.getList();
              _this.$message.success(_this.$t('operateSuccessfully'));
              _this.addForm = {};
              _this.drawer = false;
            }
            else
              _this.$message.warning(_this.$t(res.msg))
          }
        })
      })
    },
    updateAmount (row) {
      console.log(row)
    },
    transitionAmount (row) {
      return Number(row.allocation).toLocaleString();
    },
    inputAmount (from) {
      let i = from.amount.replaceAll(',', '')
      from.amount = Number(i).toLocaleString();
    },
    /**查询机构成员 */
    getOrgUser () {
      let _this = this;
      org.getOrgUserPage({
        param: { current: 1, size: 1000 },
        success: res => {
          if (res.code != 200) return;
          _this.orgUser = res.data.records;
        }
      })
    },
  },
  mounted () {
    let queryTableHeight = this.$refs.pageHead.offsetHeight + 15;
    this.queryTableHeight = queryTableHeight + 'px';

    this.getList();
    this.getOrgUser();
  }
}
</script>

<style lang="less" scoped>
</style>